import { render, staticRenderFns } from "./basicInfoForm.vue?vue&type=template&id=e5d68f42&scoped=true&"
import script from "./basicInfoForm.vue?vue&type=script&lang=js&"
export * from "./basicInfoForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5d68f42",
  null
  
)

export default component.exports